import { SetStateAction, useState } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import RoleTypeNames from "constants/RoleConstant";
import {
  QuoteHistoryDto,
  QuoteResponseDto,
  UserDto,
} from "api/PricingPlatformApi.generated";
import QuoteStatusDialog from "../win-loss/QuoteStatusDialog";
import StatusChipEditable from "components/global/common/StatusChip/StatusChipEditable";

// Define an interface for your props

interface UpdatableQuoteDto {
  hashedId: string;
  status: string;
  finalPrice: number;
}

const QuoteHistoryGrid = ({
  data,
  onRowSelection,
}: {
  data: QuoteHistoryDto[];
  onRowSelection: (selectedRows: string[]) => void;
}) => {
  const userData = useSelector<any, UserDto>(
    (state: any) => state.userRequestReducer.user
  );

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const [updatingQuoteData, setUpdatingQuoteData] =
    useState<UpdatableQuoteDto | null>(null);

  const getRowId = (row: QuoteHistoryDto) => row.hashedId!;
  const columns: GridColDef[] = [
    {
      field: "bHashedId",
      headerName: "Quote ID",
      flex: 0.5,
      renderCell: (params) => (
        <a href={`/quote/${params.row.hashedId}`}>{params.row.bHashedId}</a>
      ),
    },
    {
      field: "account",
      headerName: "Account",
      flex: 1,
      valueGetter: (value, row) => `${row.account.name}`,
    },
    { field: "equipment", headerName: "Equipment", flex: 0.5 },
    {
      field: "origin",
      headerName: "Origin",
      flex: 0.75,
      valueGetter: (value, row) =>
        `${row.stops[0].city}, ${row.stops[0].state}`,
    },
    {
      field: "destination",
      headerName: "Destination",
      flex: 0.75,
      valueGetter: (value, row) =>
        `${row.stops[1].city}, ${row.stops[1].state}`,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        const canEditStatus =
          userData.id === params.row.createdById ||
          userData.userRole === RoleTypeNames.OrgAdmin ||
          userData.userRole === RoleTypeNames.Pricing ||
          userData.isAdmin ||
          false;
        return (
          <StatusChipEditable
            status={params.row.status}
            editable={canEditStatus}
            handleClick={() => {
              setUpdatingQuoteData({
                finalPrice: params.row.totalRate,
                hashedId: params.row.hashedId,
                status: params.row.status,
              });
            }}
          />
        );
      },
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      flex: 0.5,
      renderCell: (params) => (
        <Grid container direction="column">
          <Grid item>{params.row.pickupDate?.toLocaleString()}</Grid>
          <Grid item>
            {params.row.estimatedPickupHour ? (
              params.row.estimatedPickupHour.toString().padStart(2, "0") +
              ":" +
              (params.row.estimatedPickupMinute
                ? params.row.estimatedPickupMinute.toString().padStart(2, "0")
                : "00")
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: "totalRate",
      headerName: "Total Rate",
      flex: 0.5,
      renderCell: (params) => <div>{formatCurrency(params.row.totalRate)}</div>,
    },
    {
      field: "createdByEmail",
      headerName: "Created by/on",
      flex: 0.75,
      renderCell: (params) => (
        // <Tooltip title={params.row.createdByEmail}>
        //   <Grid2 container>
        //     <span>{params.row.createdByEmail}</span>
        //     <span>{params.row.createdOn?.toLocaleString()}</span>
        //   </Grid2>
        // </Tooltip>
        <Tooltip title={params.row.createdByEmail}>
          <div>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              {params.row.createdByName}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {params.row.createdOn?.toLocaleString()}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
  ];

  const handleRowSelection = (selectedRowIds: GridRowSelectionModel) => {
    setSelectedRows(selectedRowIds);
    onRowSelection(selectedRowIds as string[]);
  };

  function setQuoteUpdateDialogOpen(value: SetStateAction<boolean>): void {
    setUpdatingQuoteData(null);
  }

  function onStatusUpdated(quote: QuoteResponseDto): void {
    //console.log("status updated:", quote, data);

    let quoteRow = data.find((qh) => qh.hashedId === quote.hashedId);
    if (!!quoteRow) {
      quoteRow.status = quote.status;
    }
    setUpdatingQuoteData(null);
  }

  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={getRowId}
        // getRowId={(row) => row.hashedId}
        checkboxSelection
        disableRowSelectionOnClick
        autoHeight={true}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          handleRowSelection(newRowSelectionModel);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        sx={{
          "& .MuiDataGrid-cell": {
            alignContent: "center",
          },
        }}
      />
      <QuoteStatusDialog
        open={!!updatingQuoteData}
        setOpen={setQuoteUpdateDialogOpen}
        quoteHashedId={updatingQuoteData?.hashedId}
        quoteStatus={updatingQuoteData?.status}
        quoteFinalRate={updatingQuoteData?.finalPrice}
        canEditStatus={true}
        canEditMarkup={false}
        onStatusUpdated={onStatusUpdated}
      />
    </Box>
  );
};

export default QuoteHistoryGrid;
