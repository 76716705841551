export const selectPageTitle = (pathName: string) => {
  const defaultTitle = "";

  const routeToTitle: Record<string, string> = {
    "/": "",
    "/quote/history": "- Quote History",
    "/quote/request": "- Request Quote",
    "/settings/pricing": "- Pricing Parameters",
    "/settings/user_management": "- User Management",
    "/settings/office_management": "- Office Management",
    "/settings/add_office": "- Add Office",
    "/settings/parameters": "- Settings",
    "/404": "- 404",
  };

  let pageTitle = routeToTitle[pathName] || defaultTitle;

  if (pageTitle === "" && pathName.startsWith("/quote/")) {
    pageTitle = `- Quote Details ${pathName.substring(7)}`;
  }

  return (document.title = `Meridian  ${pageTitle}`);
};
