import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuoteInsightsInterface } from "./interface";
import { SimilarListInsightsDto } from "api/PricingPlatformApi.generated";

const initialState: QuoteInsightsInterface = {
  similarLists: null,
};

const quoteInsightsSlice = createSlice({
  name: "quoteInsights",
  initialState,
  reducers: {
    updateListInsights: (
      state,
      action: PayloadAction<SimilarListInsightsDto>
    ) => {
      state.similarLists = action.payload;
    },
  },
});

export const { updateListInsights } = quoteInsightsSlice.actions;

export default quoteInsightsSlice.reducer;
