import React from "react";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import {
  GreenscreensDataLakeItem,
  QuoteHistoryDto,
} from "api/PricingPlatformApi.generated";
import { DateTime } from "luxon";
import { formatDateV1 } from "utilities/functions/FormatDate";

// Define an interface for your props

const SimilarQuotesTable = ({ data }: { data: QuoteHistoryDto[] }) => {
  const getRowId = (row: QuoteHistoryDto) => row.mileage ?? "";
  const columns: GridColDef[] = [
    {
      field: "hashedId",
      flex: 0.25,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Quote #</strong>
      ),
    },
    {
      field: "origin",
      renderHeader: (params: GridColumnHeaderParams) => <strong>Origin</strong>,
      flex: 0.5,
      valueGetter: (value, row) => {
        return `${row.stops[0].city}, ${row.stops[0].state} ${row.stops[0].zipCode}`;
      },
    },
    {
      field: "pickupDate",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Pickup Date</strong>
      ),
      valueGetter: (value, row) => {
        return row.pickupDate ? formatDateV1(row.pickupDate) : "";
      },
      flex: 0.5,
    },
    {
      field: "destination",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Destination</strong>
      ),
      flex: 0.5,
      valueGetter: (value, row) => {
        return `${row.stops[1].city}, ${row.stops[1].state} ${row.stops[1].zipCode}`;
      },
    },
    {
      field: "deliveryDate",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Delivery Date</strong>
      ),
      valueGetter: (value, row) => {
        return row.deliveryDate ? formatDateV1(row.deliveryDate) : "";
      },
      flex: 0.5,
    },
    {
      field: "mileage",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Mileage</strong>
      ),
      valueGetter: (value, row) => {
        return Number.parseFloat(row.mileage).toFixed(2);
      },
      type: "number",
      flex: 0.3,
    },
    {
      field: "ratePerMile",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Rate per Mile</strong>
      ),
      valueGetter: (value, row) => {
        return `$${Number.parseFloat(row.ratePerMile).toFixed(2)}`;
      },
      type: "number",
      flex: 0.3,
    },
    {
      field: "totalRate",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Total Rate</strong>
      ),
      valueGetter: (value, row) => {
        return `$${Number.parseFloat(row.totalRate).toFixed(2)}`;
      },
      type: "string",
      flex: 0.3,
    },
    {
      field: "status",
      renderHeader: (params: GridColumnHeaderParams) => <strong>Status</strong>,
      valueGetter: (value, row) => {
        return `${row.status}`;
      },
      type: "string",
      flex: 0.3,
    },
  ];

  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        rows={data as QuoteHistoryDto[]}
        columns={columns}
        getRowId={(row) => row.hashedId}
        disableRowSelectionOnClick
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        sx={{
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
            {
              marginTop: "1em",
              marginBottom: "1em",
            },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default SimilarQuotesTable;
