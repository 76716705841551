import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  LeadTimeBucketDto,
  MileageBucketDto,
  PricingParameterValueDto,
} from "../../../api/PricingPlatformApi.generated";
import { useSelector } from "react-redux";
import PricingParameterValueCell from "./PricingParamterValueCell";
import { useMemo } from "react";

const PricingParametersGrid = ({
  regionId,
  equipmentTypeId,
  mileageBuckets,
  leadTimeBuckets,
  editRegion,
  updatedValues,
  setUpdatedValues,
}: {
  regionId: number;
  equipmentTypeId: number;
  mileageBuckets: MileageBucketDto[];
  leadTimeBuckets: LeadTimeBucketDto[];
  editRegion: boolean;
  updatedValues: PricingParameterValueDto[];
  setUpdatedValues: (values: PricingParameterValueDto[]) => void;
}) => {
  // const values: PricingParameterValueDto[] = useSelector((state: any) =>
  //   state.pricingParametersReducer.pricingParameterValues
  // );

  const values: PricingParameterValueDto[] = useSelector(
    (state: any) => state.pricingParametersReducer.pricingParameterValues
  );

  const filteredValues = useMemo(
    () =>
      values.filter(
        (v: PricingParameterValueDto) =>
          v.regionId === regionId && v.equipmentTypeId === equipmentTypeId
      ),
    [equipmentTypeId, regionId, values]
  );

  const updateValue = (updatedValue: PricingParameterValueDto) => {
    const index = updatedValues.findIndex(
      (value) =>
        value.regionId === updatedValue.regionId &&
        value.equipmentTypeId === updatedValue.equipmentTypeId &&
        value.mileageBucketId === updatedValue.mileageBucketId &&
        value.leadTimeBucketId === updatedValue.leadTimeBucketId
    );
    if (index === -1) {
      // If not in the array, add it
      setUpdatedValues([...updatedValues, updatedValue]);
    } else {
      const updatedArray = [...updatedValues];
      updatedArray[index] = updatedValue;
      setUpdatedValues(updatedArray);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Mileage (miles)</TableCell>
            {leadTimeBuckets?.map((leadTime) => (
              <TableCell key={leadTime.id} align="center">
                <Grid>
                  <Typography>
                    {leadTime.maxIsInfinity
                      ? `> ${leadTime.minimumHours} Hours`
                      : `${leadTime.minimumHours} - ${leadTime.maximumHours} Hours`}
                  </Typography>
                </Grid>
              </TableCell>
            ))}

            <TableCell align="center">
              <Grid>
                <Typography>Unknown</Typography>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mileageBuckets?.map((mileage, milageRow) => {
            const valUnknown = filteredValues.find(
              (v) =>
                v.mileageBucketId === mileage.id && v.leadTimeBucketId === null
            );
            return (
              <TableRow key={mileage.id}>
                <TableCell component="th" scope="row">
                  <Grid>
                    <Typography>
                      {mileage.maxIsInfinity
                        ? `> ${mileage.minimumRange}`
                        : `${mileage.minimumRange} - ${mileage.maximumRange}`}
                    </Typography>
                  </Grid>
                </TableCell>
                {leadTimeBuckets.map((lt) => {
                  const value = filteredValues.find(
                    (v) =>
                      v.mileageBucketId === mileage.id &&
                      v.leadTimeBucketId === lt.id
                  );
                  return (
                    <TableCell
                      key={`mileage_${mileage.id}_lead_${lt.id}`}
                      align="center"
                    >
                      <PricingParameterValueCell
                        updateValue={updateValue}
                        value={value}
                        editRegion={editRegion}
                      />
                    </TableCell>
                  );
                })}
                <TableCell align="center">
                  <PricingParameterValueCell
                    updateValue={updateValue}
                    value={valUnknown}
                    editRegion={editRegion}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingParametersGrid;
