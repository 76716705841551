import { Box, Grid, Stack, Typography } from "@mui/material";
import VerticalAlignBottomIcon from "../../../../icons/VerticalAlignBottom";
import Alert from "@mui/material/Alert";

interface BatchQuouteFailBarProps {
  downloadErrors: () => void;
}

const BatchQuoteFailedBar = ({ downloadErrors }: BatchQuouteFailBarProps) => {
  return (
    <Box>
      <Grid container item xs={12} alignItems="center">
        <Grid
          item
          xs={11.7}
          sx={{
            display: "flex",
            alignItems: "right",
            backgroundColor: "#F44336",
            height: "4px",
            width: "100%",
            borderRadius: "4px 4px 0 0",
            marginBottom: "6px",
          }}
        />
        <Grid
          item
          xs={0.3}
          alignItems="right"
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          100%
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        alignItems="center"
        style={{
          padding: "16px",
          borderRadius: "4px",
          background:
            "var(--Error-Alert-Background, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #F44336)",
        }}
      >
        <Grid item xs={9}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              <Typography variant="h6">Batch Creation Failed.</Typography>
              <Typography>
                Some rows had errors and need to be fixed. Please download the
                attached error log to see the error details. Once the errors are
                fixed, please re-upload your file into the Batch Quote tool.
              </Typography>
            </Alert>
          </Stack>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <Box
            color="#952828"
            sx={{ cursor: "pointer" }}
            onClick={() => downloadErrors()}
          >
            <VerticalAlignBottomIcon />
            <span
              style={{
                marginLeft: "8px",
                marginRight: "20px",
                fontWeight: "bold",
              }}
            >
              Download Errors
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatchQuoteFailedBar;
