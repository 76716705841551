import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ListItemDto,
  PricingParameterValueDto,
  PricingRegionDto,
  UserDto,
  UserPermissionEnum,
} from "../../../api/PricingPlatformApi.generated";
import Edit from "../../../icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import PricingParametersGrid from "./PricingParametersGrid";
import { updatePricingParameters } from "redux/slices/PricingParameters/pricingParametersSlice";
import { useApiContext } from "contexts/ApiProvider";
import { showNotification } from "redux/slices/NotificationMessage/notificationMessageSlice";

const PricingRegion = ({
  region,
  equipmentType,
}: {
  region: PricingRegionDto;
  equipmentType: ListItemDto;
}) => {
  const dispatch = useDispatch();
  const { pricingParametersApi } = useApiContext();

  const userData = useSelector<any, UserDto>(
    (state: any) => state.userRequestReducer.user
  );

  const leadTimeBuckets = useSelector(
    (state: any) => state.pricingParametersReducer.leadTimeBuckets
  );
  const mileageBuckets = useSelector(
    (state: any) => state.pricingParametersReducer.mileageBuckets
  );

  const [editRegion, setEditRegion] = useState(false);

  const [updatedValues, setUpdatedValues] = useState<
    PricingParameterValueDto[]
  >([]);

  const handleSave = async () => {
    console.log("save parameters", updatedValues);
    pricingParametersApi.updatePricingParameters(updatedValues).then(() => {
      dispatch(updatePricingParameters(updatedValues));
      setUpdatedValues([]);
      setEditRegion(false);

      dispatch(
        showNotification({
          message: `Pricing Parameters updated`,
          delayMs: 2000,
          type: "success",
        })
      );
    });
  };

  return (
    <Card sx={{}} variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={12} container direction="row">
            <Grid item xs={6}>
              <Typography variant="h6">
                {region.name} | {equipmentType.label}
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end" mb={1}>
              {userData.userPermissions?.includes(
                UserPermissionEnum.EDIT_PRICING_PARAMETERS
              ) ? (
                editRegion ? (
                  <ButtonGroup variant="contained">
                    <Button onClick={handleSave}>Save</Button>
                    <Button
                      onClick={() => {
                        setEditRegion(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                ) : (
                  <IconButton
                    onClick={() => setEditRegion(true)}
                    sx={{ height: 36.5 }}
                  >
                    <Edit />
                  </IconButton>
                )
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
              {region.description}
            </Typography>
          </Grid>
        </Grid>
        <PricingParametersGrid
          regionId={region.regionId ?? 0}
          equipmentTypeId={equipmentType.value}
          leadTimeBuckets={leadTimeBuckets}
          mileageBuckets={mileageBuckets}
          editRegion={editRegion}
          updatedValues={updatedValues}
          setUpdatedValues={setUpdatedValues}
        />
      </CardContent>
    </Card>
  );
};

export default PricingRegion;
