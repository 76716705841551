import { Box, Grid, Stack, Typography } from "@mui/material";
import VerticalAlignBottomIcon from "../../../../icons/VerticalAlignBottom";
import Alert from "@mui/material/Alert";

interface BatchQuouteSuccessBarProps {
  downloadSuccessfulQuotes: () => void;
}

const BatchQuoteSuccesslBar = ({
  downloadSuccessfulQuotes,
}: BatchQuouteSuccessBarProps) => {
  return (
    <Box>
      <Grid container item xs={12} alignItems="center">
        <Grid
          item
          xs={11.7}
          sx={{
            display: "flex",
            alignItems: "right",
            backgroundColor: "#4CAF50",
            height: "4px",
            width: "100%",
            borderRadius: "4px 4px 0 0",
            marginBottom: "6px",
          }}
        />
        <Grid
          item
          xs={0.3}
          alignItems="center"
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          100%
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        style={{
          padding: "16px",
          borderRadius: "4px",
          background:
            "var(--Success-Alert-Background, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #4CAF50)",
        }}
      >
        <Grid item xs={9}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              <Typography variant="h6">Batch Created Successfully!</Typography>
              <Typography>
                Results can be downloaded to the right or accessed later from
                the table below. The included quotes are now shown in the Quote
                History as well.
              </Typography>
            </Alert>
          </Stack>
        </Grid>

        <Grid item xs={3} textAlign="right">
          <Box
            color="#4CAF50"
            sx={{ cursor: "pointer" }}
            onClick={() => downloadSuccessfulQuotes()}
          >
            <VerticalAlignBottomIcon />
            <span
              style={{
                marginLeft: "8px",
                marginRight: "20px",
                fontWeight: "bold",
              }}
            >
              Download Quotes
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatchQuoteSuccesslBar;
