import { SvgIcon } from "@mui/material";

const ArrowUpDownIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path
          d="M10.6666 11.34V6.66667H9.33325V11.34H7.33325L9.99992 14L12.6666 11.34H10.6666ZM5.99992 2L3.33325 4.66H5.33325V9.33333H6.66659V4.66H8.66659L5.99992 2Z"
          fill="#396A6A"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArrowUpDownIcon;
