import {
  Button,
  Chip,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  SimilarListInsightsDto,
  UpcomingLoadDto,
} from "api/PricingPlatformApi.generated";
import ThemeColors from "components/global/theme/theme_colors";
import ThemeStyles from "components/global/theme/theme_styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utilities/functions/FormatCurrency";

const toggleButtonActive = {
  button: {
    color: "white",
    backgroundColor: ThemeColors.metaforaGreen,
    borderRadius: "16px",
    m: "4px",
  },
  chip: {
    color: "white",
    borderColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: "transparent",
    borderRadius: "16px",
    borderThickness: "2px",
    fontSize: "12px",
    ml: "4px",
  },
  text: {
    fontSize: "12px",
    textTransform: "none",
  },
};

const toggleButtonInactive = {
  button: {
    color: "#302E2C",
    backgroundColor: ThemeColors.primaryBackground100,
    borderRadius: "16px",
    m: "4px",
  },
  chip: {
    color: ThemeColors.metaforaGreen,
    borderColor: ThemeColors.metaforaGreen,
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: "transparent",
    borderRadius: "16px",
    borderThickness: "2px",
    fontSize: "12px",
    ml: "4px",
  },
  text: {
    fontSize: "12px",
    textTransform: "none",
  },
};

const UpcomingLoadList = () => {
  const similarListInsights = useSelector<any, SimilarListInsightsDto>(
    (state: any) => state.quoteInsightsReducer.similarLists
  );

  const [list, setList] = useState<UpcomingLoadDto[] | null>(null);

  const [filter, setFilter] = useState<string>("covered");

  useMemo(() => {
    setList(similarListInsights?.upcomingLoads ?? null);
  }, [similarListInsights]);

  return (
    <Grid container>
      <Grid size={{ sm: 12 }}>
        <Button
          value="covered"
          onClick={(e) => setFilter("covered")}
          sx={{
            ...(filter === "covered"
              ? toggleButtonActive.button
              : toggleButtonInactive.button),
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                ...(filter === "covered"
                  ? toggleButtonActive.text
                  : toggleButtonInactive.text),
              }}
            >
              Covered Loads
            </Typography>
            <Chip
              sx={{
                ...(filter === "covered"
                  ? toggleButtonActive.chip
                  : toggleButtonInactive.chip),
              }}
              label={list?.filter((l) => l.covered).length}
              size="small"
            ></Chip>
            {/* <Typography
              sx={{
                ...(filter === "covered"
                  ? toggleButtonActive.chip
                  : toggleButtonInactive.chip),
              }}
            >
              {list?.filter((l) => l.covered).length}
            </Typography> */}
          </Grid>
        </Button>
        <Button
          value="uncovered"
          onClick={(e) => setFilter("uncovered")}
          sx={{
            ...(filter !== "covered"
              ? toggleButtonActive.button
              : toggleButtonInactive.button),
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Typography sx={{ ...toggleButtonActive.text }}>
              Uncovered Loads
            </Typography>
            <Chip
              sx={{
                ...(filter !== "covered"
                  ? toggleButtonActive.chip
                  : toggleButtonInactive.chip),
              }}
              label={list?.filter((l) => !l.covered).length}
              size="small"
            ></Chip>
            {/* <Typography>{list?.filter((l) => !l.covered).length}</Typography> */}
          </Grid>
        </Button>
      </Grid>
      <Grid size={{ sm: 12 }}>
        <Paper variant="outlined" sx={{ ...ThemeStyles.insightListPaper }}>
          <TableContainer sx={{ maxHeight: 380 }}>
            <Table sx={{ background: "#F9F9FA", mt: 0, pt: 0 }} stickyHeader>
              <TableHead>
                <TableRow sx={{ background: "white", pt: 0, mt: 0 }}>
                  {/* Order Number */}
                  <TableCell
                    sx={{ ...ThemeStyles.insightListHeader, width: "80px" }}
                  >
                    <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                      Order Number
                    </Typography>
                  </TableCell>

                  {/* Sell Rate */}
                  <TableCell
                    sx={{ ...ThemeStyles.insightListHeader, width: "120px" }}
                  >
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                        Sell Rate
                      </Typography>
                    </Grid>
                  </TableCell>

                  {/* Buy Rate */}
                  {filter === "covered" && (
                    <TableCell
                      sx={{ ...ThemeStyles.insightListHeader, width: "90px" }}
                    >
                      <Grid
                        container
                        sx={{ justifyContent: "end", textAlign: "end" }}
                      >
                        <Typography
                          sx={{ ...ThemeStyles.insightListHeaderText }}
                        >
                          Buy Rate
                        </Typography>
                      </Grid>
                    </TableCell>
                  )}

                  {/* Origin */}
                  <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                    <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                      Origin
                    </Typography>
                  </TableCell>

                  {/* Destination */}
                  <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                    <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                      Destination
                    </Typography>
                  </TableCell>

                  {/* Milage / LeadTime */}
                  <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                    <Grid
                      container
                      direction="column"
                      sx={{ justifyContent: "end", textAlign: "end" }}
                    >
                      <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                        Milage
                      </Typography>
                      <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                        Lead Time
                      </Typography>
                    </Grid>
                  </TableCell>

                  {/* Transportation Type */}
                  <TableCell
                    sx={{ ...ThemeStyles.insightListHeader, width: "100px" }}
                  >
                    <Grid container sx={{}}>
                      <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                        Transaction Type
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!list &&
                  [1, 2, 3, 4, 5].map((row, index) => (
                    <TableRow key={`row_skeleton_${index}`}>
                      {/* Order Number */}
                      <TableCell>
                        <Skeleton width={70} />
                      </TableCell>

                      {/* Sell Rate  */}
                      <TableCell>
                        <Grid
                          container
                          direction={"column"}
                          sx={{ alignItems: "end" }}
                        >
                          <Skeleton width={70} />
                          <Skeleton width={55} />
                        </Grid>
                      </TableCell>

                      {/* Buy Rate */}
                      {filter === "covered" && (
                        <TableCell>
                          <Grid container sx={{ justifyContent: "end" }}>
                            <Skeleton width={70} />
                          </Grid>
                        </TableCell>
                      )}

                      {/* Origin */}
                      <TableCell>
                        <Grid container direction={"column"}>
                          <Skeleton width={100} />
                          <Skeleton width={70} />
                        </Grid>
                      </TableCell>
                      {/* Destination */}
                      <TableCell>
                        <Grid container direction={"column"}>
                          <Skeleton width={100} />
                          <Skeleton width={70} />
                        </Grid>
                      </TableCell>
                      {/* Milage / LeadTime */}
                      <TableCell>
                        <Grid
                          container
                          direction={"column"}
                          sx={{ justifyContent: "end" }}
                        >
                          <Skeleton width={80} />
                          <Skeleton width={40} />
                        </Grid>
                      </TableCell>
                      {/* Transaction Type */}
                      <TableCell>
                        {row % 2 === 0 && <Skeleton width={50} />}
                        {row % 2 !== 0 && <Skeleton width={80} />}
                      </TableCell>
                    </TableRow>
                  ))}

                {list &&
                  list
                    ?.filter((l) => l.covered === (filter === "covered"))
                    .map((row, index) => (
                      <TableRow key={`row_upcomingLoad_${row.loadNumber}`}>
                        {/* Order Number */}
                        <TableCell>
                          <Link
                            underline="hover"
                            sx={{
                              cursor: "pointer",
                              color: ThemeColors.textInformation,
                            }}
                          >
                            {row.loadNumber}
                          </Link>
                        </TableCell>

                        {/* Sell Rate  */}
                        <TableCell>
                          <Grid
                            container
                            direction={"column"}
                            sx={{ alignItems: "end" }}
                          >
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnData,
                              }}
                            >
                              {formatCurrency(row.total, true)}
                            </Typography>
                            <div>
                              <Typography
                                component={"span"}
                                sx={{
                                  ...ThemeStyles.insightListColumnData,
                                }}
                              >
                                {formatCurrency(row.ratePerMile)}
                              </Typography>
                              <Typography
                                component={"span"}
                                sx={{
                                  ...ThemeStyles.insightListColumnData,
                                  color: ThemeColors.textDisabled,
                                }}
                              >
                                {" / mile"}
                              </Typography>
                            </div>
                          </Grid>
                        </TableCell>

                        {/* Buy Rate */}
                        {filter === "covered" && (
                          <TableCell>
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnData,
                                textAlign: "end",
                              }}
                            >
                              {formatCurrency(row.buyRate, true)}
                            </Typography>
                          </TableCell>
                        )}

                        {/* Origin */}
                        <TableCell>
                          <Grid container direction={"column"}>
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnData,
                              }}
                            >
                              {`${row.originCity}, ${row.originState}`}
                            </Typography>
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnDataSmall,
                              }}
                            >
                              {row.pickupDate?.toUpperCase()}
                            </Typography>
                          </Grid>
                        </TableCell>
                        {/* Destination */}
                        <TableCell>
                          <Grid container direction={"column"}>
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnData,
                              }}
                            >
                              {`${row.destinationCity}, ${row.destinationState}`}
                            </Typography>
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnDataSmall,
                              }}
                            >
                              {row.deliveryDate?.toUpperCase()}
                            </Typography>
                          </Grid>
                        </TableCell>
                        {/* Milage / LeadTime */}
                        <TableCell>
                          <Grid
                            container
                            direction={"column"}
                            sx={{ alignItems: "end" }}
                          >
                            <Grid container sx={{ whiteSpace: "break-spaces" }}>
                              <Typography
                                component="span"
                                sx={{
                                  ...ThemeStyles.insightListColumnData,
                                }}
                              >
                                {`${Math.round(row.mileage ?? 0)} `}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{
                                  ...ThemeStyles.insightListColumnDataSmall,
                                  color: ThemeColors.textDisabled,
                                }}
                              >
                                miles
                              </Typography>
                            </Grid>
                            {!!row.leadTimeHours && (
                              <Grid
                                container
                                sx={{ whiteSpace: "break-spaces" }}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    ...ThemeStyles.insightListColumnData,
                                  }}
                                >
                                  {`${row.leadTimeHours} `}
                                </Typography>
                                <Typography
                                  component="span"
                                  sx={{
                                    ...ThemeStyles.insightListColumnDataSmall,
                                    color: ThemeColors.textDisabled,
                                  }}
                                >
                                  hrs
                                </Typography>
                              </Grid>
                            )}
                            {!row.leadTimeHours && (
                              <Typography
                                component="span"
                                sx={{
                                  ...ThemeStyles.insightListColumnData,
                                }}
                              >
                                Unknown
                              </Typography>
                            )}
                          </Grid>
                        </TableCell>
                        {/* Transaction Type */}
                        <TableCell>
                          <Grid container sx={{}}>
                            <Typography
                              sx={{
                                ...ThemeStyles.insightListColumnData,
                              }}
                            >
                              {row.transactionType}
                            </Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                {list &&
                  list?.filter((l) => l.covered === (filter === "covered"))
                    .length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Typography>
                          No upcoming loads found at this time
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpcomingLoadList;
