import React from "react";

const DATLogoSmall = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119"
      height="34"
      viewBox="0 0 119 34"
      fill="none"
    >
      <path d="M73.1113 0H39.1719V33.9394H73.1113V0Z" fill="#0056FF" />
      <path d="M34.0844 0H0.14502V33.9394H34.0844V0Z" fill="#0056FF" />
      <path d="M112.138 0H78.1987V33.9394H112.138V0Z" fill="#0056FF" />
      <path
        d="M119 2.19763C119 3.40736 118.026 4.38186 116.822 4.38186C115.62 4.38186 114.645 3.40736 114.645 2.19763C114.645 0.98791 115.62 0.0268555 116.822 0.0268555C118.026 0.0268555 119 1.00135 119 2.19763ZM118.617 2.19763C118.617 1.21641 117.81 0.403212 116.816 0.403212C115.821 0.403212 115.015 1.20969 115.015 2.19763C115.015 3.18556 115.821 3.99878 116.816 3.99878C117.81 3.99878 118.617 3.1923 118.617 2.19763ZM117.75 1.828C117.75 2.14387 117.595 2.36565 117.313 2.45975L117.797 3.25279H117.246L116.809 2.50678H116.419V3.25279H115.942V1.15593H116.984C117.468 1.15593 117.75 1.41131 117.75 1.828ZM116.896 2.13043C117.132 2.13043 117.253 2.01617 117.253 1.828C117.253 1.63982 117.132 1.53229 116.896 1.53229H116.426V2.13043H116.896Z"
        fill="#0056FF"
      />
      <path
        d="M85.0539 6.05518H105.323V11.0621H98.2197V27.7697H92.1644V11.0621H85.0539V6.05518ZM15.8916 6.05518H7.34961V27.7765H15.8916C21.9469 27.7765 26.8798 22.9039 26.8798 16.9158C26.8798 10.9277 21.9536 6.05518 15.8916 6.05518ZM14.87 11.2435C18.4253 11.2435 20.6364 13.4143 20.6364 16.9158C20.6364 20.4173 18.4589 22.5948 14.8095 22.5948H13.3512V11.2435H14.87ZM68.5009 27.7765L59.3608 6.05518H52.8888L43.7487 27.7765H49.6764L51.155 24.2548H61.0949L62.5734 27.7765H68.4944H68.5009ZM58.9846 19.6981H53.2653L56.1283 12.9035L58.9846 19.6981Z"
        fill="white"
      />
    </svg>
  );
};

export default DATLogoSmall;
