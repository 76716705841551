import React, { useEffect } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import { Paper, Typography, Skeleton, Accordion } from "@mui/material";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import PricingGreenscreensNetwork from "./PricingGreenscreensNetwork";
import PricingDATRateView from "./PricingDATRateView";

interface MarketPricingProps {
  quote: QuoteResponseDto;
}

const MarketPricingV2 = (props: MarketPricingProps) => {
  useEffect(() => {}, [props.quote]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const canExpand = (pricingName: string | undefined) => {
    return true;
  };

  const handleExpand =
    (pricingName: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      //if (pricingName !== "DAT RateView") {
      setExpanded(isExpanded ? pricingName : false);
      //}
    };

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "16px 12px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #E4E5E6",
      }}
    >
      <Grid container rowGap="12px">
        <Grid size={{ xs: 12 }} container sx={{ alignItems: "center" }}>
          <Typography variant="insightLabel" sx={{ paddingRight: "18px" }}>
            Market Pricing
          </Typography>
        </Grid>
        {!props.quote &&
          [1, 2, 3].map(() => {
            return (
              <Grid size={{ xs: 12 }} sx={{ py: "10px" }} container>
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    disabled
                  >
                    <Grid container direction="column">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={240}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={80}
                      />
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            );
          })}
        {props.quote &&
          props.quote.marketPricing?.map((pricing, index) => {
            return (
              <Grid
                size={{ xs: 12 }}
                sx={{ py: "10px" }}
                container
                key={`pricing_panel_${index}`}
              >
                <Accordion
                  sx={{ width: "100%", border: "1px solid #E4E5E6" }}
                  expanded={expanded === pricing.sourceName}
                  onChange={handleExpand(pricing.sourceName ?? "")}
                >
                  <AccordionSummary
                    expandIcon={
                      canExpand(pricing.sourceName) ? <ExpandMoreIcon /> : null
                    }
                    aria-controls={`marketpricing-panel${index + 1}-content`}
                    id={`marketpricing-panel${index + 1}-header`}
                  >
                    <Grid container direction="column">
                      <Typography variant="gridText">
                        {pricing.sourceName}
                      </Typography>
                      <Typography variant="insightLabel">
                        {formatCurrency(pricing.targetBuyRate)}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {pricing.sourceName === "Greenscreens Network Rate" && (
                      <PricingGreenscreensNetwork
                        quote={props.quote}
                        pricing={pricing}
                      />
                    )}
                    {pricing.sourceName === "DAT RateView" && (
                      <PricingDATRateView
                        quote={props.quote}
                        pricing={pricing}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
      </Grid>
    </Paper>
  );
};

export default MarketPricingV2;
