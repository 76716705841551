import { useEffect, useState } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Paper,
  Divider,
  Typography,
  IconButton,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
} from "@mui/material";

import Link from "@mui/material/Link";
import { useParams } from "react-router-dom";
import { LaneInsightsDto } from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";
import TruckAlertIcon from "icons/svgs/TruckAlertIcon";
import TruckCheckIcon from "icons/svgs/TruckCheckIcon";
import PercentIcon from "icons/svgs/PercentIcon";
import FilterIcon from "icons/svgs/FilterIcon";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import ThemeColors from "components/global/theme/theme_colors";

const stylingCardIcon = {
  background: "#396A6A",
  color: "white",
  borderRadius: "8px",
  padding: "5px",
  alignSelf: "center",
  width: "34px",
  height: "34px",
};

const stylingTableHeader = {
  fontSize: "10px",
  lineHeight: "20px",
  fontFamily: "Poppins",
  color: ThemeColors.textDisabled,
  borderBottom: "0px",
};

const stylingCardIconBox = {
  alignItems: "center",
  flexFlow: "row",
  p: "2px 10px",
};

const QuoteLaneInsights = () => {
  const { quoteApi } = useApiContext();

  const { hashedId } = useParams();

  const [laneInsights, setLaneInsights] = useState<LaneInsightsDto | null>(
    null
  );
  const [range, setRange] = useState<number>(7);

  useEffect(() => {
    quoteApi.getLaneInsights(hashedId, range).then((response) => {
      setLaneInsights(response);
    });
  }, [quoteApi, hashedId, range]);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "16px 12px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #E4E5E6",
      }}
    >
      <Grid container rowGap="12px">
        <Grid size={{ xs: 12 }} container sx={{ alignItems: "center" }}>
          <Typography variant="insightLabel" sx={{ paddingRight: "18px" }}>
            Lane Insights
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid
            container
            flexDirection="column"
            sx={{ paddingLeft: "18px", flexGrow: 1 }}
          >
            <Grid container>
              {!laneInsights && (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={240}
                />
              )}
              {laneInsights && (
                <Typography variant="insightHeader">{`${laneInsights?.laneOrigin} - ${laneInsights?.laneDestination}`}</Typography>
              )}
            </Grid>
            <Typography variant="insightSubheader">{`Over the past ${range} days`}</Typography>
          </Grid>
          <IconButton sx={{ color: "#396A6A", px: "10px" }}>
            <FilterIcon />
          </IconButton>
          <Select
            value={range}
            size="small"
            onChange={(e) => setRange(Number(e.target.value))}
            sx={{ width: "128px" }}
          >
            <MenuItem value={7}>7 Days</MenuItem>
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={90}>90 Days</MenuItem>
            <MenuItem value={180}>180 Days</MenuItem>
          </Select>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ py: "10px" }} container>
          <Grid container size={{ xs: 4 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <TruckAlertIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!laneInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {laneInsights && (
                <Typography variant="insightCalloutHeader">
                  {laneInsights?.totalUncoveredLoads}
                </Typography>
              )}
              {laneInsights && (
                <Typography variant="insightCalloutText">
                  Open uncovered loads
                </Typography>
              )}
              {laneInsights && (
                <Typography
                  variant="insightCalloutText"
                  sx={{ fontWeight: 300 }}
                >
                  {`Next ${range} days`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container size={{ xs: 4 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <TruckCheckIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!laneInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {laneInsights && (
                <Typography variant="insightCalloutHeader">
                  {laneInsights?.totalRecentlyCoveredLoads}
                </Typography>
              )}

              {laneInsights && (
                <Typography variant="insightCalloutText">
                  Recently covered loads
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container size={{ xs: 4 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <PercentIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!laneInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {laneInsights && (
                <Typography variant="insightCalloutHeader">{`${laneInsights?.totalCarriers} Carriers`}</Typography>
              )}
              {laneInsights && (
                <Typography variant="insightCalloutText">
                  Recently moved loads on this lane with similar rates
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }} container columnSpacing="23px">
          <Grid size={{ md: 6 }}>
            <Paper
              sx={{
                borderRadius: "8px",
                background: "#FFF",
                border: "0.5px solid #E4E5E6",
                boxShadow: "0px 0px 6px 0px rgba(176, 195, 195, 0.25)",
              }}
            >
              <Grid
                container
                sx={{
                  borderBottom: "1px solid #E4E5E6",
                  boxShadow: "0px 0px 6px 0px rgba(176, 195, 195, 0.25)",
                  padding: "7px 10px",
                }}
                flexDirection="column"
              >
                {!laneInsights && <Skeleton width={100}></Skeleton>}
                {laneInsights && (
                  <Typography variant="insightHeader">{`Top ${laneInsights?.topCarrierReps?.length} Carrier Reps`}</Typography>
                )}

                <Typography variant="insightSubheader">{`Over the past ${range} days`}</Typography>
              </Grid>
              <Grid>
                <TableContainer sx={{ p: "8px" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ ...stylingTableHeader }}></TableCell>
                        <TableCell
                          sx={{ width: "100px", ...stylingTableHeader }}
                          align="right"
                        >
                          Loads
                        </TableCell>
                        <TableCell
                          sx={{ width: "130px", ...stylingTableHeader }}
                          align="right"
                        >
                          Avg Buy Rate
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!laneInsights &&
                        [1, 2, 3].map((row, rowIndex, array) => {
                          const lastItem = rowIndex === array.length - 1;
                          return (
                            <TableRow
                              key={`laneinsights_rep_skeleton_row_${rowIndex}`}
                            >
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {laneInsights?.topCarrierReps?.map(
                        (row, rowIndex, array) => {
                          const lastItem = rowIndex === array.length - 1;
                          return (
                            <TableRow
                              key={`laneinsights_rep_row_${rowIndex}`}
                              sx={{}}
                            >
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Link
                                  underline="hover"
                                  sx={{
                                    cursor: "pointer",
                                    color: ThemeColors.textInformation,
                                  }}
                                >
                                  {row.name}
                                </Link>
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                {row.numberLoads}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  fontWeight: "600",
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                {formatCurrency(row.averageBuyRate)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
          </Grid>
          <Grid size={{ md: 6 }}>
            <Paper
              sx={{
                borderRadius: "8px",
                background: "#FFF",
                border: "0.5px solid #E4E5E6",
                boxShadow: "0px 0px 6px 0px rgba(176, 195, 195, 0.25)",
              }}
            >
              <Grid
                container
                sx={{
                  borderBottom: "1px solid #E4E5E6",
                  boxShadow: "0px 0px 6px 0px rgba(176, 195, 195, 0.25)",
                  padding: "7px 10px",
                }}
                flexDirection="column"
              >
                {!laneInsights && <Skeleton width={100}></Skeleton>}
                {laneInsights && (
                  <Typography variant="insightHeader">{`Top ${laneInsights?.topCarriers?.length} Carriers`}</Typography>
                )}
                <Typography variant="insightSubheader">{`Over the past ${range} days`}</Typography>
              </Grid>
              <Grid>
                <TableContainer sx={{ p: "8px" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ ...stylingTableHeader }}></TableCell>
                        <TableCell
                          sx={{ width: "100px", ...stylingTableHeader }}
                          align="right"
                        >
                          Loads
                        </TableCell>
                        <TableCell
                          sx={{ width: "130px", ...stylingTableHeader }}
                          align="right"
                        >
                          Avg Buy Rate
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!laneInsights &&
                        [1, 2, 3].map((row, rowIndex, array) => {
                          const lastItem = rowIndex === array.length - 1;
                          return (
                            <TableRow
                              key={`laneinsights_carrier_skeleton_row_${rowIndex}`}
                            >
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Skeleton variant="text" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {laneInsights?.topCarriers?.map(
                        (row, rowIndex, array) => {
                          const lastItem = rowIndex === array.length - 1;
                          return (
                            <TableRow
                              key={`laneinsights_carrier_row_${rowIndex}`}
                              sx={{}}
                            >
                              <TableCell
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                <Link
                                  underline="hover"
                                  sx={{
                                    cursor: "pointer",
                                    color: ThemeColors.textInformation,
                                  }}
                                >
                                  {row.name}
                                </Link>
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                {row.numberLoads}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  fontWeight: "600",
                                  ...(lastItem && { borderBottomWidth: "0px" }),
                                }}
                              >
                                {formatCurrency(row.averageBuyRate)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuoteLaneInsights;
