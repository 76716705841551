import React from "react";
import Chip from "@mui/material/Chip";

export type StatusChipProps = {
  status?: "Won" | "Lost" | "Pending" | "Unknown";
};

const StatusChip = ({ status }: { status: string | undefined }) => {
  const statusMappings = {
    Won: {
      label: "Won",
      color: "success",
    },
    Lost: {
      label: "Lost",
      color: "error",
    },
    Pending: {
      label: "Pending",
      color: "default",
    },
    Unknown: {
      label: "Unknown",
      color: "default",
    },
  };

  const { label, color } = getStatus(status);

  function getStatus(status: string | undefined) {
    switch (status) {
      case "Won":
        return statusMappings.Won;
      case "Lost":
        return statusMappings.Lost;
      case "Pending":
        return statusMappings.Pending;
      case "Unknown":
      default:
        return statusMappings.Unknown;
    }
  }

  return (
    <Chip
      label={label}
      color={color as "primary" | "secondary" | "default" | "success" | "error"}
      variant="outlined"
      size="small"
      sx={{ background: "white" }}
    />
  );
};

export default StatusChip;
