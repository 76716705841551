import {
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  HistoricalLoadDto,
  SimilarListInsightsDto,
} from "api/PricingPlatformApi.generated";
import ThemeColors from "components/global/theme/theme_colors";
import ThemeStyles from "components/global/theme/theme_styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utilities/functions/FormatCurrency";

const HistoricalLoadsList = () => {
  const similarListInsights = useSelector<any, SimilarListInsightsDto>(
    (state: any) => state.quoteInsightsReducer.similarLists
  );

  const [list, setList] = useState<HistoricalLoadDto[] | null>(null);

  useMemo(() => {
    setList(similarListInsights?.historicalLoads ?? null);
  }, [similarListInsights]);

  return (
    <Paper variant="outlined" sx={{ ...ThemeStyles.insightListPaper }}>
      <TableContainer sx={{ maxHeight: 380 }}>
        <Table sx={{ background: "#F9F9FA", mt: 0, pt: 0 }} stickyHeader>
          <TableHead>
            <TableRow sx={{ background: "white", pt: 0, mt: 0 }}>
              {/* Load Number */}
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "80px" }}
              >
                <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                  Load Number
                </Typography>
              </TableCell>
              {/* Sell Rate */}
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "120px" }}
              >
                <Grid container sx={{ justifyContent: "end" }}>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Sell Rate
                  </Typography>
                </Grid>
              </TableCell>

              {/* Buy Rate */}
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "90px" }}
              >
                <Grid
                  container
                  sx={{ justifyContent: "end", textAlign: "end" }}
                >
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Buy Rate
                  </Typography>
                </Grid>
              </TableCell>

              {/* Origin */}
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                  Origin
                </Typography>
              </TableCell>
              {/* Destination */}
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                  Destination
                </Typography>
              </TableCell>
              {/* Milage / LeadTime */}
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Grid
                  container
                  direction="column"
                  sx={{ justifyContent: "end", textAlign: "end" }}
                >
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Milage
                  </Typography>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Lead Time
                  </Typography>
                </Grid>
              </TableCell>
              {/* Transportation Type */}
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "100px" }}
              >
                <Grid container sx={{}}>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Transaction Type
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!list &&
              [1, 2, 3, 4, 5].map((row, index) => (
                <TableRow key={`row_skeleton_${index}`}>
                  {/* Load Number */}
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                  {/* Sell Rate  */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ alignItems: "end" }}
                    >
                      <Skeleton width={70} />
                      <Skeleton width={55} />
                    </Grid>
                  </TableCell>
                  {/* Buy Rate */}
                  <TableCell>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Skeleton width={70} />
                    </Grid>
                  </TableCell>

                  {/* Origin */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Skeleton width={100} />
                      <Skeleton width={70} />
                    </Grid>
                  </TableCell>
                  {/* Destination */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Skeleton width={100} />
                      <Skeleton width={70} />
                    </Grid>
                  </TableCell>
                  {/* Milage / LeadTime */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ justifyContent: "end" }}
                    >
                      <Skeleton width={80} />
                      <Skeleton width={40} />
                    </Grid>
                  </TableCell>
                  {/* Transaction Type */}
                  <TableCell>
                    {row % 2 === 0 && <Skeleton width={50} />}
                    {row % 2 !== 0 && <Skeleton width={80} />}
                  </TableCell>
                </TableRow>
              ))}

            {list &&
              list?.map((row, index) => (
                <TableRow key={`row_historicalLoad_${row.loadNumber}`}>
                  {/* Load Number */}
                  <TableCell>
                    <Link
                      underline="hover"
                      sx={{
                        cursor: "pointer",
                        color: ThemeColors.textInformation,
                      }}
                    >
                      {row.loadNumber}
                    </Link>
                  </TableCell>
                  {/* Sell Rate  */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ alignItems: "end" }}
                    >
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {formatCurrency(row.total, true)}
                      </Typography>
                      <div>
                        <Typography
                          component={"span"}
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                          }}
                        >
                          {formatCurrency(row.ratePerMile)}
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                            color: ThemeColors.textDisabled,
                          }}
                        >
                          {" / mile"}
                        </Typography>
                      </div>
                    </Grid>
                  </TableCell>
                  {/* Buy Rate */}
                  <TableCell>
                    <Typography
                      sx={{
                        ...ThemeStyles.insightListColumnData,
                        textAlign: "end",
                      }}
                    >
                      {formatCurrency(row.buyRate, true)}
                    </Typography>
                  </TableCell>
                  {/* Origin */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {`${row.originCity}, ${row.originState}`}
                      </Typography>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnDataSmall,
                        }}
                      >
                        {row.pickupDate?.toUpperCase()}
                      </Typography>
                    </Grid>
                  </TableCell>
                  {/* Destination */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {`${row.destinationCity}, ${row.destinationState}`}
                      </Typography>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnDataSmall,
                        }}
                      >
                        {row.deliveryDate?.toUpperCase()}
                      </Typography>
                    </Grid>
                  </TableCell>
                  {/* Milage / LeadTime */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ alignItems: "end" }}
                    >
                      <Grid container sx={{ whiteSpace: "break-spaces" }}>
                        <Typography
                          component="span"
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                          }}
                        >
                          {`${Math.round(row.mileage ?? 0)} `}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            ...ThemeStyles.insightListColumnDataSmall,
                            color: ThemeColors.textDisabled,
                          }}
                        >
                          miles
                        </Typography>
                      </Grid>
                      {!!row.leadTimeHours && (
                        <Grid container sx={{ whiteSpace: "break-spaces" }}>
                          <Typography
                            component="span"
                            sx={{
                              ...ThemeStyles.insightListColumnData,
                            }}
                          >
                            {`${row.leadTimeHours} `}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              ...ThemeStyles.insightListColumnDataSmall,
                              color: ThemeColors.textDisabled,
                            }}
                          >
                            hrs
                          </Typography>
                        </Grid>
                      )}
                      {!row.leadTimeHours && (
                        <Typography
                          component="span"
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                          }}
                        >
                          Unknown
                        </Typography>
                      )}
                    </Grid>
                  </TableCell>
                  {/* Transaction Type */}
                  <TableCell>
                    <Grid container sx={{}}>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {row.transactionType}
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            {list && list?.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography>
                    No historical loads found at this time
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HistoricalLoadsList;
