import Grid from "@mui/material/Grid2";
import {
  Paper,
  IconButton,
  Tabs,
  Box,
  Tab,
  Typography,
  Button,
  Skeleton,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApiContext } from "contexts/ApiProvider";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateListInsights } from "redux/slices/QuoteInsights/quoteInsightsSlice";
import SimilarQuotesList from "./SimilarQuotesList";
import CustomerQuotesList from "./CustomerQuotesList";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import { dayMMDDYYYY } from "utilities/time-formatting/luxon-util";
import { DateTime } from "luxon";
import ArrowUpDownIcon from "icons/svgs/ArrrowUpDownIcon";
import ArrowExpandIcon from "icons/svgs/ArrrowExpandIcon";
import HistoricalLoadsList from "./HistoricalLoadsList";
import UpcomingLoadList from "./UpcomingLoadList";

const insightStyles = {
  listFilterHeader: {
    fontSize: "10px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
    color: "#302E2C",
  },
  listFilterSubheader: {
    fontSize: "8px",
    fontWeight: "300",
    lineHeight: "13px",
    fontFamily: "Roboto",
    color: "#595856",
  },
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 1,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InsightLists = () => {
  const [value, setValue] = useState<number>(0);

  const [range, setRange] = useState<number>(7);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  const { hashedId } = useParams();
  const { quoteApi } = useApiContext();
  const dispatch = useDispatch();

  useEffect(() => {
    quoteApi.getListInsights(hashedId).then((response) => {
      console.log("updating similar quotes", response);
      dispatch(updateListInsights(response));
    });
  }, [dispatch, hashedId, quoteApi]);

  useEffect(() => {}, []);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "16px 12px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #E4E5E6",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{}}>
          <Grid container>
            {/* <Grid ></Grid> */}
            <Tabs
              sx={{ flex: 1 }}
              value={value}
              onChange={handleChange}
              aria-label="Quote & Load Insights"
            >
              <Tab
                label="Similar Quotes"
                {...a11yProps(0)}
                sx={{
                  borderBottom: ".5px solid #E4E5E6",
                  fontWeight: value === 0 ? "600" : "400",
                }}
              />
              <Tab
                label="Customer Quotes"
                {...a11yProps(1)}
                sx={{
                  borderBottom: ".5px solid #E4E5E6",
                  fontWeight: value === 1 ? "600" : "400",
                }}
              />
              <Tab
                label="Upcoming Loads"
                {...a11yProps(2)}
                sx={{
                  borderBottom: ".5px solid #E4E5E6",
                  fontWeight: value === 2 ? "600" : "400",
                }}
              />
              <Tab
                label="Historical Loads"
                {...a11yProps(3)}
                sx={{
                  borderBottom: ".5px solid #E4E5E6",
                  fontWeight: value === 3 ? "600" : "400",
                }}
              />
            </Tabs>

            <Divider
              flexItem
              orientation="vertical"
              sx={{ ml: "8px", mr: "12px", my: "4px" }}
            />

            {!quoteData && (
              <Grid container sx={{ alignContent: "center" }}>
                <Grid container direction={"column"} sx={{ mr: "8px" }}>
                  <Skeleton width={240} />
                  <Skeleton width={100} />
                </Grid>
                <IconButton sx={{ color: "#396A6A", px: "10px" }} disabled>
                  <ArrowExpandIcon />
                </IconButton>
              </Grid>
            )}

            {quoteData && value === 1 && (
              <Grid container sx={{ alignItems: "center" }}>
                <Typography
                  sx={{ ...insightStyles.listFilterHeader, mr: "8px" }}
                  component="span"
                >{`${quoteData.account?.name}`}</Typography>

                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<ArrowUpDownIcon />}
                  sx={{
                    borderColor: "green",
                    borderRadius: "6px",
                    fontSize: "10px",
                    height: "24px",
                    textTransform: "none",
                  }}
                >
                  Recent First
                </Button>
                <IconButton sx={{ color: "#396A6A", px: "10px" }}>
                  <ArrowExpandIcon />
                </IconButton>
              </Grid>
            )}
            {quoteData && [0, 3].includes(value) && (
              <Grid container sx={{ alignContent: "center" }}>
                <Grid container direction={"column"} sx={{ mr: "8px" }}>
                  <Typography
                    sx={{ ...insightStyles.listFilterHeader }}
                    component="span"
                  >{`${quoteData.stops?.[0].pricingRegion} - ${quoteData.stops?.[1].pricingRegion}`}</Typography>
                  <Typography
                    sx={{ ...insightStyles.listFilterSubheader }}
                    component="span"
                  >{`${quoteData.equipmentType?.label} | Pickup Date within 30 days`}</Typography>
                </Grid>
                <IconButton sx={{ color: "#396A6A", px: "10px" }}>
                  <ArrowExpandIcon />
                </IconButton>
              </Grid>
            )}
            {quoteData && value === 2 && (
              <Grid container sx={{ alignContent: "center" }}>
                <Grid container direction={"column"} sx={{ mr: "8px" }}>
                  <Typography
                    sx={{ ...insightStyles.listFilterHeader }}
                    component="span"
                  >{`${quoteData.stops?.[0].pricingRegion} - ${quoteData.stops?.[1].pricingRegion}`}</Typography>
                  <Typography
                    sx={{ ...insightStyles.listFilterSubheader }}
                    component="span"
                  >{`${quoteData.equipmentType?.label} | Pickup ${dayMMDDYYYY(quoteData.pickupDate ?? DateTime.now())}`}</Typography>
                </Grid>
                <IconButton sx={{ color: "#396A6A", px: "10px" }}>
                  <ArrowExpandIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SimilarQuotesList />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CustomerQuotesList />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <UpcomingLoadList />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <HistoricalLoadsList />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default InsightLists;
