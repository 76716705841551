import ThemeColors from "./theme_colors";

const ThemeStyles = {
  insightListPaper: {
    padding: "16px 12px",
    gap: "12px",
    borderRadius: "8px",
    border: "1px solid #E4E5E6",
    background: "#F9F9FA",
    boxShadow: "0px 0px 2.8px 0px rgba(151, 150, 149, 0.24)",
  },
  insightListHeader: { pt: "6px", pb: "4px", backgroundColor: "white" },

  insightListHeaderText: {
    fontSize: "10px",
    color: ThemeColors.textDisabled,
    fontFamily: "Poppins",
  },
  insightListColumnData: {
    color: "#302E2C",
    fontSize: "12px",
    lineHeight: "16px",
  },
  insightListColumnDataSmall: {
    color: "#595856",
    fontSize: "10px",
    lineHeight: "16px",
  },
};

export default ThemeStyles;
