import { useEffect, useState } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Divider,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  DatProjectedCost,
  MarketPricingDto,
  QuoteResponseDto,
} from "api/PricingPlatformApi.generated";
import ThemeColors from "components/global/theme/theme_colors";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import DATLogo from "icons/DATLogo";
import ArrowDownIcon from "icons/svgs/ArrrowDownIcon";
import ArrowUpIcon from "icons/svgs/ArrrowUpIcon";
import DATLogoSmall from "icons/DATLogoSmall";

interface MarketPricingProps {
  quote: QuoteResponseDto;
  pricing: MarketPricingDto;
}

const PricingDATRateView = (props: MarketPricingProps) => {
  const [pricing, setPricing] = useState<DatProjectedCost | null>(null);
  const [timeFrame, setTimeFrame] = useState<string>("");

  useEffect(() => {
    if (
      props.quote.projectedCost?.datProjectedCosts !== undefined &&
      props.quote.projectedCost?.datProjectedCosts?.length > 0
    ) {
      setPricing(props.quote.projectedCost?.datProjectedCosts[0]);
      setTimeFrame(props.quote.projectedCost?.datProjectedCosts[0].days!);
    } else {
      setPricing(null);
      setTimeFrame("");
    }
  }, [props.quote]);

  const onDATSelectionChange = (event: SelectChangeEvent<typeof timeFrame>) => {
    let index = props.quote.projectedCost?.datProjectedCosts?.findIndex(
      (c) => c.days === event.target.value
    );

    if (
      index !== undefined &&
      index >= 0 &&
      props.quote.projectedCost?.datProjectedCosts !== undefined
    ) {
      setPricing(props.quote.projectedCost?.datProjectedCosts![index]);

      setTimeFrame(event.target.value);
    }
  };

  return (
    <Grid
      container
      sx={{ background: ThemeColors.paperBackground, padding: "4px 17px" }}
    >
      <Grid
        size={{ xs: 12 }}
        container
        sx={{ mb: "16px", mt: "8px", alignItems: "center" }}
      >
        <Grid container size={{ xs: 4 }}>
          <Typography variant="cardTextHeader" sx={{ fontSize: "16px" }}>
            Current Spot Rate
          </Typography>
        </Grid>
        <Grid container size={{ xs: 4 }}>
          <FormControl size="small">
            <InputLabel id="time-frame-select-label">Time Frame</InputLabel>
            <Select
              labelId="time-frame-select-filled-label"
              id="time-frame-select-filled"
              value={timeFrame}
              onChange={onDATSelectionChange}
              label="Time Frame"
              placeholder="Time Frame"
              size="small"
              sx={{ width: "160px" }}
            >
              {props.quote.projectedCost?.datProjectedCosts?.map(
                (price, index) => (
                  <MenuItem value={price.days} key={`timeFrame_${index}`}>
                    {price.days}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid container size={{ xs: 4 }} sx={{ justifyContent: "end" }}>
          <DATLogoSmall />
        </Grid>
      </Grid>

      <Grid container size={{ xs: 12 }}>
        <Grid container size={{ xs: 8 }} sx={{}} rowGap={2} rowSpacing={"2px"}>
          <Grid size={{ xs: 4 }} direction="column">
            <Typography variant="cardTextLargeHeader" sx={{ fontSize: "16px" }}>
              Predicted Rate
            </Typography>
            <Typography
              sx={{
                color: "#302E2C",
                fontSize: "20px",
                lineHeight: "20px",
              }}
            >
              {formatCurrency(pricing?.predictedRate! * pricing?.mileage!)}
            </Typography>
          </Grid>

          {/* Low */}
          <Grid
            container
            size={{ xs: 4 }}
            sx={{
              padding: " 7px 0px",
              ml: "-6px",
              alignItems: "center",
            }}
          >
            <ArrowDownIcon
              sx={{
                color: ThemeColors.metaforaGreen,
                width: "24px",
                height: "24px",
                mr: "6px",
              }}
            />
            <Grid container flexDirection="column">
              <Typography variant="cardTextHeader">Low</Typography>
              <Typography variant="cardText">
                {formatCurrency(pricing?.lowRate! * pricing?.mileage!)}
              </Typography>
            </Grid>
          </Grid>

          {/* High */}
          <Grid
            container
            size={{ xs: 4 }}
            sx={{
              padding: "7px 0px",
              //ml: "-6px",
              alignItems: "center",
            }}
          >
            <ArrowUpIcon
              sx={{
                color: ThemeColors.metaforaGreen,
                width: "24px",
                height: "24px",
                mr: "6px",
              }}
            />
            <Grid container flexDirection="column">
              <Typography variant="cardTextHeader">High</Typography>
              <Typography variant="cardText">
                {formatCurrency(pricing?.highRate! * pricing?.mileage!)}
              </Typography>
            </Grid>
          </Grid>

          <Grid size={{ xs: 4 }} container flexDirection="column">
            <Typography variant="cardTextHeader">Origin</Typography>
            <Typography variant="cardText">{`${pricing?.origin}`}</Typography>
          </Grid>
          <Grid size={{ xs: 4 }} container flexDirection="column">
            <Typography variant="cardTextHeader">Destination</Typography>
            <Typography variant="cardText">{`${pricing?.destination}`}</Typography>
          </Grid>
          <Grid size={{ xs: 4 }} container flexDirection="column">
            <Typography variant="cardTextHeader">Mileage</Typography>
            <Typography variant="cardText">{`${pricing?.mileage?.toFixed(2)}`}</Typography>
          </Grid>

          <Grid size={{ xs: 4 }} container flexDirection="column">
            <Typography variant="cardTextHeader"># of reports</Typography>
            <Typography variant="cardText">{`${pricing?.reportsCount}`}</Typography>
          </Grid>
          <Grid size={{ xs: 4 }} container flexDirection="column">
            <Typography variant="cardTextHeader"># of Companies</Typography>
            <Typography variant="cardText">{`${pricing?.companiesCount}`}</Typography>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid container size={{ xs: 3 }} rowGap={2} rowSpacing={"2px"}>
          <Grid size={{ xs: 12 }} container flexDirection="column">
            <Typography variant="cardTextHeader">
              Load to Truck Ratio
            </Typography>
            <Typography variant="cardText">{`${pricing?.loadToTruckRatio}`}</Typography>
            <Typography variant="cardText">{`${pricing?.origin}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PricingDATRateView;
