import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInterface } from "./interface";
import {
  RequestQuoteOptionsDto,
  UserDto,
} from "api/PricingPlatformApi.generated";

const initialState: UserInterface = {
  user: new UserDto(),
  options: new RequestQuoteOptionsDto(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.user = action.payload.profile;
      state.options = action.payload.options;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export const { saveUser, updateUser, updateOptions } = userSlice.actions;

export default userSlice.reducer;
