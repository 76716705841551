export const formatCurrency = (
  value?: number | string | undefined,
  round: boolean = false
): string | null => {
  if (!value) return null;

  let UsDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: round ? 0 : 2,
  });

  let val = Number(value);
  if (round) {
    val = Math.round(val);
  }

  return `${UsDollar.format(val)}`;
};
