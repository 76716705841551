import {
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  QuoteHistoryDto,
  SimilarListInsightsDto,
} from "api/PricingPlatformApi.generated";
import StatusChip from "components/global/common/StatusChip/StatusChip";
import ThemeColors from "components/global/theme/theme_colors";
import ThemeStyles from "components/global/theme/theme_styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import { formatDateV1 } from "utilities/functions/FormatDate";

const CustomerQuotesList = () => {
  const similarListInsights = useSelector<any, SimilarListInsightsDto>(
    (state: any) => state.quoteInsightsReducer.similarLists
  );

  const [list, setList] = useState<QuoteHistoryDto[] | null>(null);

  useMemo(() => {
    setList(similarListInsights?.customerQuotes ?? null);
  }, [similarListInsights]);

  return (
    <Paper variant="outlined" sx={{ ...ThemeStyles.insightListPaper }}>
      <TableContainer sx={{ maxHeight: 380 }}>
        <Table sx={{ background: "#F9F9FA", mt: 0, pt: 0 }} stickyHeader>
          <TableHead>
            <TableRow sx={{ background: "white", pt: 0, mt: 0 }}>
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "80px" }}
              >
                <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                  Quote ID
                </Typography>
              </TableCell>

              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "120px" }}
              >
                <Grid container sx={{ justifyContent: "end" }}>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Sell Rate
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "90px" }}
              >
                <Grid
                  container
                  sx={{ justifyContent: "end", textAlign: "end" }}
                >
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Expected Buy Rate
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                  Origin
                </Typography>
              </TableCell>
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                  Destination
                </Typography>
              </TableCell>
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Grid
                  container
                  direction="column"
                  sx={{ justifyContent: "end", textAlign: "end" }}
                >
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Milage
                  </Typography>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Lead Time
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...ThemeStyles.insightListHeader }}>
                <Grid container sx={{}}>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Equipment
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell
                sx={{ ...ThemeStyles.insightListHeader, width: "100px" }}
              >
                <Grid container sx={{ justifyContent: "center" }}>
                  <Typography sx={{ ...ThemeStyles.insightListHeaderText }}>
                    Status
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!list &&
              [1, 2, 3, 4, 5].map((row, index) => (
                <TableRow key={`row_skeleton_${index}`}>
                  {/* Quote ID */}
                  <TableCell>
                    <Skeleton width={50} />
                  </TableCell>
                  {/* Sell Rate  */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ alignItems: "end" }}
                    >
                      <Skeleton width={70} />
                      <Skeleton width={55} />
                    </Grid>
                  </TableCell>
                  {/* Buy Rate */}
                  <TableCell>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Skeleton width={70} />
                    </Grid>
                  </TableCell>

                  {/* Origin */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Skeleton width={100} />
                      <Skeleton width={70} />
                    </Grid>
                  </TableCell>
                  {/* Destination */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Skeleton width={100} />
                      <Skeleton width={70} />
                    </Grid>
                  </TableCell>
                  {/* Milage / LeadTime */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ justifyContent: "end" }}
                    >
                      <Skeleton width={80} />
                      <Skeleton width={40} />
                    </Grid>
                  </TableCell>
                  {/* Status */}
                  <TableCell>
                    <Skeleton width={60} height={40} />
                  </TableCell>
                </TableRow>
              ))}

            {list &&
              list?.map((row, index) => (
                <TableRow key={`row_similarQuote_${row.hashedId}`}>
                  {/* Quote ID */}
                  <TableCell>
                    <Link
                      underline="hover"
                      href={`/quote/${row.hashedId}`}
                      sx={{
                        cursor: "pointer",
                        color: ThemeColors.textInformation,
                      }}
                    >
                      {row.hashedId}
                    </Link>
                    {/* <Typography>{row.hashedId}</Typography> */}
                  </TableCell>
                  {/* Total  */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ alignItems: "end" }}
                    >
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {formatCurrency(row.totalRate, true)}
                      </Typography>
                      <div>
                        <Typography
                          component={"span"}
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                          }}
                        >
                          {formatCurrency(row.ratePerMile)}
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                            color: ThemeColors.textDisabled,
                          }}
                        >
                          {" / mile"}
                        </Typography>
                      </div>
                    </Grid>
                  </TableCell>
                  {/* Rate */}
                  <TableCell>
                    <Typography
                      sx={{
                        ...ThemeStyles.insightListColumnData,
                        textAlign: "end",
                      }}
                    >
                      {formatCurrency(row.targetBuyRate, true)}
                    </Typography>
                  </TableCell>

                  {/* Origin */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {row.stops &&
                          `${row.stops[0].city}, ${row.stops[0].state}`}
                      </Typography>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnDataSmall,
                        }}
                      >
                        {row.pickupDate && formatDateV1(row.pickupDate)}
                      </Typography>
                    </Grid>
                  </TableCell>
                  {/* Destination */}
                  <TableCell>
                    <Grid container direction={"column"}>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnData,
                        }}
                      >
                        {row.stops &&
                          `${row.stops[row.stops.length - 1].city}, ${row.stops[row.stops.length - 1].state}`}
                      </Typography>
                      <Typography
                        sx={{
                          ...ThemeStyles.insightListColumnDataSmall,
                        }}
                      >
                        {row.deliveryDate && formatDateV1(row.deliveryDate)}
                      </Typography>
                    </Grid>
                  </TableCell>
                  {/* Milage / LeadTime */}
                  <TableCell>
                    <Grid
                      container
                      direction={"column"}
                      sx={{ alignItems: "end" }}
                    >
                      <Grid container sx={{ whiteSpace: "break-spaces" }}>
                        <Typography
                          component="span"
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                          }}
                        >
                          {`${row.mileage} `}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            ...ThemeStyles.insightListColumnDataSmall,
                            color: ThemeColors.textDisabled,
                          }}
                        >
                          miles
                        </Typography>
                      </Grid>
                      {!!row.leadTimeInHours && (
                        <Grid container sx={{ whiteSpace: "break-spaces" }}>
                          <Typography
                            component="span"
                            sx={{
                              ...ThemeStyles.insightListColumnData,
                            }}
                          >
                            {`${row.leadTimeInHours} `}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              ...ThemeStyles.insightListColumnDataSmall,
                              color: ThemeColors.textDisabled,
                            }}
                          >
                            hrs
                          </Typography>
                        </Grid>
                      )}
                      {!row.leadTimeInHours && (
                        <Typography
                          component="span"
                          sx={{
                            ...ThemeStyles.insightListColumnData,
                          }}
                        >
                          Unknown
                        </Typography>
                      )}
                    </Grid>
                  </TableCell>
                  {/* Equipment */}
                  <TableCell>
                    <Typography
                      sx={{
                        ...ThemeStyles.insightListColumnData,
                      }}
                    >
                      {row.equipment}
                    </Typography>
                  </TableCell>
                  {/* Status */}
                  <TableCell>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <StatusChip status={row.status}></StatusChip>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            {list && list?.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography>No customer quotes found at this time</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomerQuotesList;
